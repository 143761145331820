import React from "react";
import CareerDetailsSection from "~sections/CareerDetails/CareerDetailsSection"
import { PageWrapper } from "~components/Core";

import FooterFive from '~sections/agency/FooterFive'

import HeaderButton from '~sections/project/Header'


const header = {
  headerClasses: "site-header site-header--menu-start light-header site-header--sticky",
  containerFluid: false,
  // buttonBlock: (
  //   <HeaderButton
  //     className="ms-auto d-none d-xs-inline-flex"
  //     btnOneText="Login"
  //     btnTwoText="Get Started For Free"
  //     mr="15px"
  //     mrLG="0"
  //   />
  // ),
}

export default function CareerDetails() {
  return (
    <PageWrapper innerPage={true}>
      <CareerDetailsSection />
      <FooterFive />
    </PageWrapper>
  )
}
